@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);


/* Utils */

@mixin edithover() {
    &::before {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-family: FontAwesome;
        content: "\f040"
    }
}

button.plain {
    border-color: transparent;
    border-style: solid;
    padding: none;
    background: none;

    &:hover {
        border-color: #ccc;
    }

    &.left {
        text-align: left;
    }
}

tbody>tr>td.buttonfill {
    padding: 0;

    >button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0.75rem;
        border: none;
        &:hover {
            color: #007bff;
            @include edithover();
        }
        outline: none;
    }

    >div {
        padding: 0.25em 0.75rem;
    }
}

.pulsate {
    animation: pulsate 3s ease-out;
    animation-iteration-count: infinite; 
    opacity: 0.5;
}
@keyframes pulsate {
    0% { 
        opacity: 0.5;
    }
    50% { 
        opacity: 1.0;
    }
    100% { 
        opacity: 0.5;
    }
}

/* Bootstrap tweaks */

.tab-body {
    padding: 1em;
}

ul.nav.nav-tabs {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}

div.input-group {
        /*>div.btn-group, >div.dropdown {
            >button {
                border-radius: 0;
                border-top: 0;
                border-bottom: 0;
                border-left: 0;
                height: 100%;
            }
        }*/
        /*>span.input-group-text {
            border-radius: 0;
            border-top: 0;
            border-bottom: 0;
            border-left: 0;
            height: 100%;
        }*/
        >:last-child>* {
            border-right: 0;
        }

    >span.plain.input-group-text {
        background-color: #fff;
        border-color: #6c757d;
    }
}

th>div.input-group {
    >input {
        border-top: 0;
        border-bottom: 0;
    }

    * {
        border-radius: 0;
        
    }

    >* {
        border-color: #ced4da;

        >* {
            border-color: #ced4da;
        }
    }
}

div.input-group-no-bottom-radius {
    >* {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        > * {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

span.input-group-inline-text {
    margin-left: -1px;
    margin-right: -1px;
    border-radius: 0;
}

thead.stickyHead {
    position: sticky;
    top: 2.5em;
    background-color: #fff;
    border-bottom: 2px solid #ccc;
    z-index: 11;

    :first-child>th {
        border-bottom-width: 0px;
    }

    >tr>th>div.input-group {
        >input {
            border: 1px solid #ccc;
        }
    }

}

.pdftooltip > .tooltip-inner {
    max-width: 100%;
}

/* Font stuff */


.vsmall {
    font-size: 0.6em;
}

.small {
    font-size: 0.8em;
}

.temphist {
    margin: 0.2em 0;
    font-size: 0.8em;
    font-style: italic;
}


/* Sites */

table.stTable {
    >thead {
        >th {
            vertical-align: top;
        }
    }
}

.assT {
    >div {
    border-bottom: 1px solid #ccc;
    padding: 0 0 0.5em; 
    margin-bottom: 0.5em;
    }
}



/* Settings */

div.settings-intro {
    padding: 1em;
}

/* Files */
img.prevImg {
    max-width: 100%;
}